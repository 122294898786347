<template>
  <div class="x-off-footer">
    <div class="x-off-tp">
      <div class="x-off-tp-con">
        <div class="footer-logo">
          <el-image
            style="width: 348px; height: 68px"
            :src="require('@/assets/logo_bottom.png')"
          ></el-image>
        </div>
        <div class="footer-box">
          <div class="footer-item">
            <span @click="handleClick('/#news')">行业新闻</span>
            <span @click="handleClick('/#tax')">税务规划服务</span>
            <span @click="handleClick('/#enterprise')">关于税务规划</span>
            <span @click="handleClick('/#consult')">预约咨询</span>
          </div>
          <div class="footer-item">
            <span class="lian-us">联系我们</span>
            <div class="footer-item-box">
              <el-image
                style="width: 35px; height: 32px"
                :src="require('@/assets/icon_location.png')"
              ></el-image>
              <span class="footer-am">安徽省安庆市望江县经济开发区望江大道58号1号楼102室</span>
            </div>
            <div class="footer-item-box">
              <el-image
                style="width: 32px; height: 32px"
                :src="require('@/assets/icon_call.png')"
              ></el-image>
              <span class="footer-am">4006060390</span>
            </div>
          </div>
        </div>
      </div> 
    </div>
    <div class="x-off-bt">
      <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank"> 备案号：皖ICP备2024052700号-1</a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    handleClick(tab) {
      const url = this.$router.resolve({ path: tab }).href;
      window.open(url, '_self');
    },
  },
};
</script>
<style lang="scss" scoped>
.x-off-footer {
  width: 100%;
  height: 100%;

  .x-off-tp {
    width: 100%;
    height: 400px;
    display: flex;
    justify-content: space-between;
    background: #3067C9;
    .x-off-tp-con{
      width: 1100px;
      height: 100%;
      margin: 0 auto;
      overflow: hidden;
      .footer-logo{
        width: 100%;
        height: 68px;
        margin-top: 60px;
        margin-bottom: 29px;
        text-align: left;
      }
      .footer-box{
        width: 100%;
        box-sizing: border-box;
        padding-left: 84px;
        display: flex;
        .footer-item{
          width: 50%;
          display: flex;
          flex-direction: column;
          text-align: left;
          span{
            font-size: 18px;
            color: #fff;
            text-decoration: none;
            margin-bottom: 29px;  
            cursor: pointer;
          }
          .lian-us{
            font-size: 18px;
            color: #fff;
            text-decoration: none;
            margin-bottom: 29px;  
          }
          .footer-item-box{
            display: flex;
            align-items: center;
            margin-bottom: 17px;
            width: 100%;
            height: 40px;
            .footer-am{
              font-size: 16px;
              color: #fff;
              margin-left: 9px !important;
              margin-bottom: 0 !important;
              display: block;
              line-height: 40px;
              padding: 0 !important;
            }
          }
        }
      }
    }
  }
  .x-off-bt {
    width: 100%;
    height: 80px;
    background: #1849A2;
    font-size: 16px;
    color: #fff;
    line-height: 80px;
    text-align: center;
    a{
      font-size: 16px;
      color: #fff;
      text-decoration: none;
    }
  }
}
</style>
